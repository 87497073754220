import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { RequestRegisterQueryParams, ResetPasswordRegister } from '../../../shared/models/request.model';
import { ApiResponse } from '../../../shared/models/common.model';
import { initialResponseData } from '../../../shared/utils/constants';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../../shared/components/table-header-ordering';
import { RequestService } from '../../../shared/services/request-service';
import { AxiosError } from 'axios';
import { notificationService } from '../../../shared/services/notification-service';
import TableRowNoData from '../../../shared/components/molecules/commons/table-row-no-data';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import InputReasonModal from '../../../shared/components/molecules/modals/input-reason-modal';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../../shared/components/molecules/commons/pagination-form';
import Moment from 'moment/moment';
import { useAuth } from '../../../shared/authentications/auth-context';
import { hasPermission } from '../../../shared/utils/roles';

interface ResetPasswordRequestRow extends ResetPasswordRegister {
    onAction: (status: 'approve' | 'reject', id: any) => void;
}

const ResetPasswordRequestsPage: FC = () => {
    const [queryParams, setQueryParams] = useState<RequestRegisterQueryParams>({
        search: '',
        ordering_field: '',
        ordering: '',
        page: 1,
        page_size: 10,
    });
    const [data, setData] = useState<ApiResponse<ResetPasswordRegister>>(initialResponseData);
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const [showConfirmModal, setConfirmModal] = useState(false);
    const [showRejectModal, setRejectModal] = useState(false);
    const [idTarget, setIdTarget] = useState<any>(null);

    const headers: Header[] = [
        {
            label: 'Username',
            field: 'username',
            order: null,
            isOrder: true,
        },
        {
            label: 'ชื่อ - นามสกุล',
            field: 'full_name_th',
            order: null,
            isOrder: true,
        },
        {
            label: 'อีเมล',
            field: 'email',
            order: null,
            isOrder: true,
        },
        {
            label: 'เบอร์โทรศัพท์',
            field: 'phone',
            order: null,
            isOrder: true,
        },
        {
            // label: 'ใช้งานครั้งล่าสุด',
            label: 'เวลาส่งคำขอ',
            // field: 'last_login',
            field: 'created_at',
            order: null,
            isOrder: true,
        },
        {
            label: 'สถานะ',
            field: '',
            order: null,
            isOrder: false,
        },
        {
            label: '',
            field: '',
            order: null,
            isOrder: false,
        }
    ];

    useEffect(() => {
        fetchData();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    const fetchData = async () => {
        startLoading();
        try {
            const result = await RequestService.getResetPasswordRequest(queryParams);
            setData(result);
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const onChangePageSize = (page_size: number) => {
        setQueryParams({
            ...queryParams,
            page_size: page_size
        });
    };

    const onChangePage = (page: number) => {
        setQueryParams({
            ...queryParams,
            page: page
        });
    };

    const tableRowNoData = () => {
        if (data.rows.length === 0) {
            return <TableRowNoData colspan={7}/>;
        }
        return null;
    };

    const onChangeOrder = (orderData: OrderData) => {
        setQueryParams({
            ...queryParams,
            ordering_field: orderData.field,
            ordering: orderData.order,
        });
    };

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams(prevState => {
            return {...prevState, search: e.target.value};
        });
    };

    const onAction = (status: 'approve' | 'reject', id: any) => {
        setIdTarget(id);
        if (status === 'approve') {
            setConfirmModal(true);
        } else {
            setRejectModal(true);
        }
    };

    const onApprove = async () => {
        handleCloseModal();
        startLoading();
        try {
            await RequestService.onApproveResetPassword(idTarget, {});
            notificationService.successNotification('อนุมัติสำเร็จ', '');
            await fetchData();
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('อนุมัติไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const onReject = async (reason: any) => {
        handleCloseModal();
        startLoading();
        const data = {
            reason: reason
        };
        try {
            await RequestService.onRejectResetPassword(idTarget, data);
            notificationService.successNotification('ไม่อนุมัติสำเร็จ', '');
            await fetchData();
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('ไม่อนุมัติไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const handleCloseModal = () => {
        setConfirmModal(false);
        setRejectModal(false);
    };

    const displayModal = () => {
        if (showConfirmModal) {
            return <ConfirmModal
                show={showConfirmModal}
                title={'ยืนยันการอนุมัติคำขอ'}
                description={'คุณแน่ใจหรือไม่ว่าต้องการอนุมัติคำขอนี้'}
                onHide={handleCloseModal}
                onConfirm={onApprove}
            />;
        }
        if (showRejectModal) {
            return <InputReasonModal
                show={showRejectModal}
                title={'ยืนยันการไม่อนุมัติคำขอ'}
                description={'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติคำขอนี้'}
                onHide={handleCloseModal}
                onConfirm={onReject}
            />;
        }
        return;
    };

    return <>
        {loaderComponent}
        {displayModal()}
        <div className="row">
            <div className="col-sm-12 col-md-3">
                <input className="form-control" type="text" value={queryParams.search}
                       onChange={onChangeSearch}
                       placeholder="ค้นหา..." name="search"/>
            </div>
            <div className="col-sm-12 col-md-2">
                <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </Button>
            </div>
        </div>

        <div className="overflow-auto mt-3">
            <Table striped bordered hover>
                <thead>
                <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
                </thead>
                <tbody>
                {
                    data.rows.map((data, index) => {
                        return <ResetPasswordRequestRow key={data.id}
                                                        {...data}
                                                        onAction={onAction}
                        />;
                    })
                }
                {tableRowNoData()}
                </tbody>
            </Table>
        </div>
        <div className="">
            <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                            onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
        </div>
    </>;
};

const ResetPasswordRequestRow: FC<ResetPasswordRequestRow> = ({
                                                                  id,
                                                                  status,
                                                                  created_by_employee,
                                                                  created_at,
                                                                  onAction
                                                              }) => {
  const { permission} = useAuth();

    const getStatusDisplay = () => {
        if (status === 'WAITING_FOR_ACTIONS')
            return 'รอการอนุมัติ';
        if (status === 'APPROVED')
            return 'อนุมัติ';
        if (status === 'REJECTED')
            return 'ไม่อนุมัติ';
    };

    const handleOnApprove = () => {
        onAction('approve', id);
    };

    const handleOnReject = () => {
        onAction('reject', id);
    };

    const displayButton = () => {
        if (status === 'WAITING_FOR_ACTIONS')
            return hasPermission(permission, ['employee_reset_password_requests'], 'approve') ? <>
                <Button variant="primary" className="btn-mof-primary" onClick={handleOnApprove}>
                    อนุมัติ
                </Button>
                <Button variant="danger" className="text-white ms-2" onClick={handleOnReject}>
                    ไม่อนุมัติ
                </Button>
            </> : null;
        return <>
        </>;
    };

    const getLastLoginFormat = () => {
        return created_by_employee?.user?.last_login ?
            Moment(created_by_employee?.user?.last_login).add(543, 'year').format('DD/MM/YYYY HH:mm:ss')
            : '';
    };

    const getCreatedAtFormat = () => {
        return created_at ?
            Moment(created_at).add(543, 'year').format('DD/MM/YYYY HH:mm:ss')
            : '';
    };

    return <tr>
        <td>{created_by_employee?.user?.username}</td>
        <td>{created_by_employee.full_name_th}</td>
        <td>{created_by_employee?.user?.email}</td>
        <td>{created_by_employee.phone}</td>
        <td>{ getCreatedAtFormat() /* getLastLoginFormat() */ }</td>
        <td>{getStatusDisplay()}</td>
        <td>
            {
                displayButton()
            }
        </td>
    </tr>;
};

export default ResetPasswordRequestsPage;